import React from 'react';
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
  return (
    <div className="lg:relative bg-gray-50">
        <div className="mx-auto  w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left">
          <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
            <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl">
              <span className="block xl:inline">We help our customers to keep their websites safe and secure.</span>              
            </h1>
            <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <a href="https://app.shieldstack.io/" className="inline-block rounded-md border border-transparent bg-purple-600 px-5 py-4 text-base font-medium text-white shadow hover:bg-purple-700 sm:px-10">
                  Get Started
                </a>
            </div>
          </div>
        </div>
        <div className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
            <StaticImage src="../../images/about.jpg" 
                alt="about-us" 
                loading="eager"
                placeholder="blurred"
                className="absolute inset-0 h-full w-full object-cover"
            />         
        </div>
      </div>
  )
}

export default Hero