import * as React from "react"
import { HeadFC } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/about/hero"
import Intro from "../components/about/intro"
import AwardWinning from "../components/about/award-winning"

const AboutPage = () => {
  return (
    <Layout>
      <Hero/>
      <Intro/>
      <AwardWinning/>
    </Layout>
  )
}

export default AboutPage

export const Head: HeadFC = () => <title>About Shieldstack | Hosting and Web Security Platform</title>
